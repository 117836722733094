<template>
    <div class="vip-content" style="background-color: #F9FAFA;">
        <div style="width: 90%;margin: 0 auto;height: 182px;">
            <div v-if="auth_type==0" >
                <div style="height: 44px;background-color:#EBF0F2;text-align: center;line-height: 44px;">当前未开通会员服务</div>
                <div style="height: 138px;text-align: center;line-height: 138px;color:#0079F9;background-color: #FFFFFF;cursor: pointer;font-weight: 400;"  @click="goBuy()">立即前往开通或续费 >></div>
            </div>
            <div v-else>
                <div style="display: inline-block; width: 49%;margin-right: 2%; background-color: #FFA055;height: 182px;vertical-align: top;">
                    <div style="font-size:16px;color:white;margin-left:5%;margin-top:12px;">{{vipName}}-{{vip_type_str}}</div>
                    <div style="border-bottom: 1px solid white;width: 90%;margin-left: 5%;margin-top:10px;"></div>
                    <div style="font-size:14px;color:white;margin-left:5%;margin-top:12px;">
                            会员类型：{{vipName}} <br>选曲范围：会员专区+音效库 <br>到期时间：{{coop_time}}
                    </div>
                    <div style="font-size:14px;color:white;margin-left:5%;margin-top:12px;">
                            今日/首	   已下载 {{today_dl}}	   可下载 {{dl}}	   剩余下载 {{remain_dl}}
                    </div>
                    
                </div>
                <div style="display: inline-block;width: 49%;background-color: #FFA055;height: 182px;vertical-align: top;" v-if="!auth_data_str">
                    <div style="font-size:16px;color:white;margin-left:5%;margin-top:12px;width:90%;">授权主体 
                        <span style="color:#0079F9;float:right;" @click="onAdd">立即添加</span>
                    </div>
                    <div style="border-bottom: 1px solid white;width: 90%;margin-left: 5%;margin-top:10px;"></div>
                    <div style="font-size:14px;color:white;margin-left:5%;margin-top:12px;">
                            当前为{{vipName}}，支持添加 <span style="color: #EF3131;">1</span>  个{{auth_type_str}}主体<br>
                            暂无主体信息<br><br>
                    </div>
                    <div style="font-size:12px;color:#333333;margin-left:5%;margin-top:12px;">
                        <span style="color:#EF3131">*</span>添加主体后即可下载高清音乐，主体信息将用于生成电子版授权书
                    </div>
                </div>
                <div style="display: inline-block;width: 49%;background-color: #FFA055;height: 182px;vertical-align: top;" v-else>
                    <div style="font-size:16px;color:white;margin-left:5%;margin-top:12px;width:90%;">授权主体</div>
                    <div style="border-bottom: 1px solid white;width: 90%;margin-left: 5%;margin-top:10px;"></div>
                    <div style="font-size:14px;color:white;margin-left:5%;margin-top:12px;white-space:pre-line;height: 63px;text-align: left;">{{auth_data_str}}
                    </div>
                    <div style="font-size:12px;color:#333333;margin-left:5%;margin-top:12px;">
                        <span style="color:#EF3131">*</span>该主体信息将用于生成电子版授权书，不支持自主修改，如有疑问请联系客服
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="cont-header">
            <span>开通记录</span>
        </div> -->
        <div  class="cont">
            <div style="height: 60px;text-align: center;line-height: 60px;background-color: #FFFFFF;">
                <div style="height: 60px;line-height: 60px;color:#FF7B13;width:64px;margin-left:18px;border-bottom: 1px solid #FF7B13;">购买记录</div>
                    
            </div>
            <a-row v-for="(scope, key) in dataTableList" :key="key" style="margin-top: 16px;">
                <a-col :span="24" class="list-header"
                    style="line-height:40px;background: #F4F6F6;border: 1px solid #EBF0F2;padding-left: 1%;height:40px;">
                    <a-col span="4"><span>商品类型</span></a-col>
                    <a-col span="2"><span>时长</span></a-col>
                    <a-col span="4"><span>购买时间</span></a-col>
                    
                    <a-col span="4"><span>到期时间</span></a-col>
                    <a-col span="3"><span>付款方式</span></a-col>
                    <a-col span="7">
                        <span>权益说明</span>
                        <!-- <img src="@/assets/images/web/music/status_on.svg" style="position: relative;float: right;"
                            v-if="scope.is_enalbe==1" />
                        <img src="@/assets/images/web/music/status_off.svg" style="position: relative;float: right;"
                            v-else> -->
                    </a-col>
                </a-col>
                <a-col :span="24"
                    style="height:130px;border: 1px solid #EBF0F2;display:flex;align-items:center;padding-left: 1%;background-color: #FFFFFF;">
                    <a-col :span="4">
                        <!-- <img v-if="scope.auth_type==1" src="@/assets/images/web/music/vip_company.svg">
                        <img v-if="scope.auth_type==2" src="@/assets/images/web/music/vip_personal.svg"> -->
                        <img src="@/assets/images/web/member/个人vip.png" v-if="scope.auth_type==2" alt="个人VIP">
                        <img src="@/assets/images/web/member/企业VIP.png" v-if="scope.auth_type==1" alt="企业VIP">
                        <img src="@/assets/images/web/member/省钱卡.png" v-if="scope.auth_type==3" alt="省钱卡">
                    </a-col>
                    <a-col :span="2">
                        <span v-if="scope.vip_type==1">月度</span>
                        <span v-if="scope.vip_type==2">季度</span>
                        <span v-if="scope.vip_type==3">年度</span>
                        <span v-if="scope.vip_type==4">定制</span>
                    </a-col>
                    <a-col :span="4">
                        <span v-if="scope.order.create_at">{{scope.order.create_at.substring(0,10)}}</span>
                    </a-col>
                    <a-col :span="4">
                        <span>{{scope.coop_end_date}}</span>
                    </a-col>
                    <a-col :span="3">{{scope.payway}}

                    </a-col>
                    <a-col :span="7" style="text-align:left;margin-bottom: 0;">
                        <a-col :span="24" v-if="scope.auth_type != 3">
                            <p v-if="scope.vip_type==4" style="margin-bottom: 0;">曲库类型：全部曲库</p>
                            <p v-if="scope.vip_type!=4" style="margin-bottom: 0;">选曲范围：会员专区+音效库</p>
                            <p style="margin-bottom: 0;">下载限制：<span v-if="scope.download_num == -1">不限量/天</span><span v-else>{{scope.download_num}}</span>
                            </p>
                            <p v-if="scope.auth_type==1" style="margin-bottom: 0;">授权说明：企业商用</p>
                            <p v-if="scope.auth_type==2" style="margin-bottom: 0;">授权说明：个人（非商用）</p>
                        </a-col>
                        <a-col :span="24" v-else>
                            <p v-if="scope.vip_type==1" style="width: 80%;">当月立即获得零售曲库8折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用）</p>
                            <p v-else style="width: 80%;">每月获得零售曲库6折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用）</p>
                        </a-col>
                    </a-col>
                </a-col>
            </a-row>
            <a-row v-if="dataTableList.length == 0" style="text-align:center;padding-top:24px;">
                <img src="@/assets/images/empty.png" style="width:76px;" />
                <br /><span style="color:rgba(0, 0, 0, 0.25);">暂无数据</span>
            </a-row>
        </div>
        <auth  @close='onClose()' v-if="showAuth" :order_id='order_id' :auth_type='auth_type'></auth>
    </div>
</template>
<script>
    import { Table as ATable } from 'ant-design-vue';
    import auth from './auth.vue'
    export default {
        data() {
            return {
                loading: true,
                vipName:"企业会员",
                showAuth:false,
                pagination: {
                    "show_pagin": true,
                    "show-quick-jumper": true,
                    "default-current": 1,
                    total: 0,
                    pageSize: 20,
                    current: 0,
                    onChange: this.onChangePage,
                    hideOnSinglePage: true,
                    
                    style: {
                        "float": "none",
                        "text-align": "center",
                    },
                },
                columns: [
                    {
                        title: 'VIP类型',
                        dataIndex: 'vip-type',
                        align: 'center',
                        scopedSlots: { customRender: 'vip-type' },
                    },
                    {
                        title: '开通时间',
                        align: 'center',
                        dataIndex: 'coop_start_date',
                    },
                    {
                        title: '到期时间',
                        align: 'center',
                        dataIndex: 'coop_end_date',
                    },
                    {
                        title: '权限说明',
                        dataIndex: 'auth_scene_name',
                        align: 'left',
                        width: '40%',
                        scopedSlots: { customRender: 'auth-info' },
                    },
                ],
                dataTableList: [],
                auth_data_str:"",
                LoadpersonalData:false,
                LoadcompanyData:false,
                // personalData:null,
                // companyData:null,
                auth_type_str:"",
                vip_type_list:['','月度','季度','年度'],
                vip_type_str:"",
                dl:0,
                all_dl:0,
                remain_dl:0,
                today_dl:0,
                coop_time:"",
                paywayArr:["","微信","支付宝","公对公"],
                order_id:'',
                auth_type:0,
            }
        },
        components: {
            ATable,
            auth,
        },
        created: function () {
            
            this.setUser();
        },
        watch:{
            userInfo(newVal,oldVal){
                this.setUser();
            }
        },
        computed: {
            userInfo() {
                return Object.assign({}, this.$store.state.userInfo);
            },
        },
        methods: {
            onClose(){
                this.showAuth = false;
                this.setUser();
            },
            goBuy(){
                this.$router.push({path:'/subscribe_introduce_v2'});
            },
            setUser(){
                // console.log(this.userInfo);
                if (this.userInfo.company_vip == 1){
                    this.LoadcompanyData = true;
                    this.LoadpersonalData = false;
                    this.vipName = "企业会员";
                    this.auth_type_str = "公司";
                    this.auth_type = 1;
                }else if(this.userInfo.person_vip == 1){
                    this.LoadpersonalData = true;
                    this.LoadcompanyData = false;
                    this.vipName = "个人会员";
                    this.auth_type_str = "个人";
                    this.auth_type = 2;
                }
                this.getAuthData();
                this.getTableData();
            },
            onAdd(){
                // console.log("---------")
                this.showAuth = true;
            },
            getTableData(params) {
                this.$axios.vipgoodsList(params).then(res => {
                    if (res.data.code == 0) {
                        this.dataTableList = res.data.data.list;
                        for(var j=0;j<this.dataTableList.length;j++){
                            var vip_ = this.dataTableList[j];
                            vip_.payway = this.paywayArr[vip_.order.pay_way];
                        }
                        
                        this.pagination.total = res.data.data.total;
                        this.loading = false;

                        var dd = new Date();
                        var m = dd.getMonth()+1;
                        m = m<10?"0"+m:m;
                        var d = dd.getDate();
                        d = d<10?"0"+d:d;
                        var today = dd.getFullYear()+"-"+m+"-"+d;
                        
                        var isCompany = false;
                        for(var i=0;i<this.dataTableList.length;i++){
                            var vip = this.dataTableList[i];
                            if(vip.auth_type==1 ){
                                if (today >= vip.coop_start_date && today <= vip.coop_end_date ){
                                    this.dl = vip.download_num;
                                    this.today_dl = vip.today_dl;
                                    this.all_dl = vip.all_dl;
                                    if (this.dl == -1){
                                        this.dl = "∞";
                                        this.remain_dl = "∞";
                                    }else{
                                        this.remain_dl = this.dl - this.all_dl;
                                    }
                                    this.order_id = vip.order.order_id;
                                    this.vip_type_str = this.vip_type_list[vip.vip_type];
                                }
                                
                                if ( vip.coop_end_date > this.coop_time){
                                    this.coop_time = vip.coop_end_date;
                                }
                                console.log(vip.coop_end_date);
                                isCompany = true;
                            }
                            if(vip.auth_type==2 ){
                                if (isCompany){
                                    continue;
                                }
                                if (today >= vip.coop_start_date && today <= vip.coop_end_date ){
                                    this.dl = vip.download_num;
                                    this.today_dl = vip.today_dl;
                                    this.all_dl = vip.all_dl;
                                    if (this.dl == -1){
                                        this.dl = "∞";
                                        this.remain_dl = "∞";
                                    }else{
                                        this.remain_dl = this.dl - this.all_dl;
                                    }
                                    this.order_id = vip.order.order_id;
                                    this.vip_type_str = this.vip_type_list[vip.vip_type];
                                }
                                // this.coop_time = vip.coop_end_date;
                                if ( vip.coop_end_date > this.coop_time){
                                    this.coop_time = vip.coop_end_date;
                                }
                                
                                
                            }
                            
                        }
                    }
                })
                
            },
            getAuthData(){
                this.$axios.vipAuthlist().then(res => {
                    if (res.data.code == 0) {
                        let auth_data = res.data.data.list;
                        var arr = [];
                        if (auth_data[1]){
                            arr.push(auth_data[1]);
                        }
                        if(auth_data[2]){
                            arr.push(auth_data[2]);
                        }
                        for (var i = 0; i<arr.length;i++){
                            var item = arr[i];
                            if (item.auth_type == "1" && this.LoadcompanyData){
                                this.auth_data_str = "公司名称："+item.name+"\n";
                                this.auth_data_str += "纳税人识别号：" +item.due+"\n";
                                this.auth_data_str += "联系方式：" +item.phone;
                            }else if(item.auth_type == "2" && this.LoadpersonalData){
                                this.auth_data_str = "姓名："+item.name+"\n";
                                this.auth_data_str += "联系方式：" +item.phone;
                            }
                        }
                        
                    }
                })
            },
            onChangePage(nowpage) {
                this.loading = true;
                let params = {
                    page: nowpage,
                }
                this.getTableData(params);
                this.pagination.current = nowpage;
                this.pagination = Object.assign({}, this.pagination);
            },
        },
    }
</script>
<style lang="scss" scoped>
    .cont-header {
        height: 66px;
        line-height: 66px;
        border-bottom: 1px solid #F0F4F5;

        span {
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            height: 22px;
        }

        span:nth-child(1) {
            width: 20%;
            padding-left: 32px;
        }

        span:nth-child(2) {
            cursor: pointer;
            width: 80%;
            color: #FF7B13;
            text-align: right;
            padding-right: 32px;
        }
    }

    .cont {
        width: 90%;
        margin: 0 auto;
        padding-top: 24px;
    }

    .auth-info {
        p {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
        }

        p:nth-child(4) {
            color: #333333;
        }
    }
</style>